import React from 'react'
import copy from 'copy-to-clipboard'
import CopyButton from '../BoxItem/CopyButton'
import Dropdown from '../BoxItem/Dropdown'
import useCopy from '../../utils/use-copy'

const ComponentItem = ({ item }) => {
  const [copied, setCopied] = useCopy()

  return (
    <li className="relative flex flex-col-reverse">
      <h3>{item.name}</h3>
      <div className="relative mb-3 h-24 group">
        <div className="group absolute inset-0 w-full border border-gray-200">
          <button
            type="button"
            className="border-transparent border-2 absolute w-full inset-0 outline-none focus:outline-none"
            onClick={() => {}}
            disabled
          >
            <div
              className="utility-code-inside p-4 absolute inset-0 w-full flex items-center justify-center"
              dangerouslySetInnerHTML={{ __html: item.code }}
            />
          </button>
          <Dropdown>
            <CopyButton
              copied={copied}
              onClick={() => {
                copy(item.code)
                setCopied(true)
              }}
              disabled={copied}
              text="Copy SVG"
            />
          </Dropdown>
        </div>
      </div>
    </li>
  )
}

export default ComponentItem
