import React, { useEffect, useState } from 'react'
import { collectionItemsGet } from '../../api/collections'
import { checkHttpStatus } from '../../utils/api'
import Spinner from '../spinner'
import BoxItem from '../BoxItem'
import usePrevious from '../../utils/use-previous'
import { nameByType } from '../../utils/collection'

const Colors = ({ id, onItemClick = () => {}, q, activeItemId, pageSize, type }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [results, setResults] = useState([])
  const [total, setTotal] = useState(null)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [searchQuery, setSearchQuery] = useState(q)
  const prevSearchQuery = usePrevious(searchQuery)

  useEffect(() => {
    setSearchQuery(q)
    setPage(null)
    setTotal(null)
    setHasMore(null)
  }, [q])

  useEffect(() => {
    setError(null)
    if (id && (page || prevSearchQuery !== searchQuery)) {
      setLoading(true)
      collectionItemsGet({ id, searchQuery, types: type, pageSize, page: page || 1 })
        .then(checkHttpStatus)
        .then(data => {
          setResults(r => (page > 1 ? [...r, ...data.results] : data.results))
          setHasMore(Boolean(data?.links?.next))
          setLoading(false)
          setTotal(data.total)
          if (data.total < 1) {
            setError('Nothing found')
          }
        })
        .catch(err => {
          setLoading(false)
          setError('Something went wrong')
        })
    }
  }, [id, page, searchQuery, prevSearchQuery, pageSize, type])

  return (
    <>
      <section className="contents">
        <h3 className="flex-none text-2xl leading-6 font-normal text-gray-900 mr-3 mt-8 mb-6">{nameByType[type]}</h3>
        {loading && !page ? (
          <div className="w-full flex justify-center">
            <Spinner className="text-purple-500" />
          </div>
        ) : (
          <ul className="gap-2 gap-x-8 gap-y-4 grid grid-cols-2 items-start leading-4 lg:gap-x-4 lg:grid-cols-8 mb-8 sm:gap-x-4 sm:gap-y-8 sm:grid-cols-4 text-center">
            {results.map(item => (
              <li key={item.uuid} className="relative flex flex-col-reverse">
                <h3>{item.name}</h3>
                <div className="relative mb-3 h-24">
                  <BoxItem isActive={item.id === activeItemId} onItemClick={() => onItemClick(item)} item={item} />
                </div>
              </li>
            ))}
          </ul>
        )}
      </section>

      {error && (
        <div className="w-full flex justify-center">
          <span className="text-gray-900">{error}</span>
        </div>
      )}

      {hasMore && (
        <div className="text-center">
          <span className="block mb-5">{`showing ${results.length} out of ${total}`}</span>
          <button
            className="border border-purple-500 text-purple-500 hover:text-white hover:bg-purple-500 opacity-80 hover:opacity-100 outline-none focus:outline-none h-10 w-40 inline-flex items-center justify-center font-medium leading-none rounded-lg shadow-sm hover:shadow-lg px-5 duration-150"
            type="button"
            disabled={loading}
            onClick={() => setPage((page || 1) + 1)}
          >
            {loading ? <Spinner /> : <span>Load More</span>}
          </button>
        </div>
      )}
    </>
  )
}

export default Colors
