import React from 'react'
import { IconGridUniform, IconEffects } from '../../components/icons'
import copy from 'copy-to-clipboard'
import { normalizeCss as normalizeColorsCss, getItemCss as getColorsItemCss } from '../../utils/style-colors'
import { getItemCss as getEffectsItemCss } from '../../utils/style-effects'
import { getItemCss as getTextItemCss } from '../../utils/style-text'
import CopyButton from './CopyButton'
import OpenButton from './OpenButton'
import Dropdown from './Dropdown'
import useCopy from '../../utils/use-copy'

const BoxItem = ({ onItemClick, item, isActive }) => {
  const [copied, setCopied] = useCopy()

  return (
    <>
      {item.item_type === 1 && (
        <div
          className={`${isActive ? 'border-purple-400' : 'border-gray-200'} group absolute inset-0 w-full border`}
          style={{
            background: normalizeColorsCss(item.code.paints)
          }}
        >
          <>
            <button
              type="button"
              className={`${
                isActive ? 'border-purple-400' : 'border-transparent'
              } border absolute w-full inset-0 outline-none focus:outline-none`}
              onClick={onItemClick}
            />
            <Dropdown>
              <CopyButton
                copied={copied}
                onClick={() => {
                  copy(getColorsItemCss(item.code.paints))
                  setCopied(true)
                }}
                disabled={copied}
                text="Copy CSS"
              />
              <OpenButton onClick={onItemClick} />
            </Dropdown>
          </>
        </div>
      )}

      {item.item_type === 2 && (
        <div className="group absolute inset-0 w-full border border-gray-200">
          <button
            type="button"
            className={`${
              isActive ? 'border-purple-400' : 'border-transparent'
            } border absolute w-full inset-0 outline-none focus:outline-none`}
            onClick={onItemClick}
          >
            <span className="text-xl text-gray-700">{item.code.fontName.family.substring(0, 1)}</span>
          </button>
          <Dropdown>
            <CopyButton
              copied={copied}
              onClick={() => {
                copy(getTextItemCss(item.code))
                setCopied(true)
              }}
              disabled={copied}
              text="Copy CSS"
            />
            <OpenButton onClick={onItemClick} />
          </Dropdown>
        </div>
      )}
      {item.item_type === 3 && (
        <div className="group absolute inset-0 w-full border border-gray-200">
          <button
            type="button"
            className={`${
              isActive ? 'border-purple-400' : 'border-transparent'
            } border absolute w-full inset-0 outline-none focus:outline-none`}
            onClick={onItemClick}
          >
            <IconEffects className="m-auto block" />
          </button>
          <Dropdown>
            <CopyButton
              copied={copied}
              onClick={() => {
                copy(getEffectsItemCss(item.code.effects))
                setCopied(true)
              }}
              disabled={copied}
              text="Copy CSS"
            />
            <OpenButton onClick={onItemClick} />
          </Dropdown>
        </div>
      )}
      {item.item_type === 4 && (
        <div className="group absolute inset-0 w-full border border-gray-200">
          <button
            type="button"
            className={`${
              isActive ? 'border-purple-400' : 'border-transparent'
            } border absolute w-full inset-0 outline-none focus:outline-none`}
            onClick={onItemClick}
          >
            <IconGridUniform className="m-auto block" />
          </button>
          <Dropdown>
            <OpenButton onClick={onItemClick} />
          </Dropdown>
        </div>
      )}
    </>
  )
}

export default BoxItem
