import React from 'react'

const NotFound = () => {
  return (
    <div className="flex justify-center py-16 text-purple-800 w-full">
      <span>Not found</span>
    </div>
  )
}

export default NotFound
