import { PaintTypeGraident } from './figmaTypes'
import { normalizeColor, rgb2hexCss } from './style-base'

const normalizeFillType = fillType => (fillType && PaintTypeGraident[fillType] ? PaintTypeGraident[fillType] : null)

const getGradientSteps = gradientStops => {
  if (!gradientStops) return null

  return gradientStops.map(item => `${normalizeColor(item.color)} ${Math.round(item.position * 100)}%`)
}

const getLinearCss = gradientStops => {
  if (!gradientStops) return null

  const steps = getGradientSteps(gradientStops)

  return `linear-gradient(180deg, ${steps.join(', ')})`
}

const getRadialCss = gradientStops => {
  if (!gradientStops) return null

  const steps = getGradientSteps(gradientStops)

  return `radial-gradient(50% 50% at 50% 50%, ${steps.join(', ')})`
}

const getAngularCss = gradientStops => {
  if (!gradientStops) return null

  const steps = getGradientSteps(gradientStops)

  return `conic-gradient(from 180deg at 50% 50%, ${steps.join(', ')})`
}

const getDiamondCss = gradientStops => {
  if (!gradientStops) return null

  const steps = getGradientSteps(gradientStops)
  return `radial-gradient(50% 50% at 50% 50%, ${steps.join(', ')})`
}

export const getOpacityPercent = opacity => {
  if (!opacity) return null

  return `${opacity * 100}%`
}

export const getImageCss = () => {
  return 'url(Checker.png)'
}

export const getTitleByType = paint => {
  return paint.type === 'SOLID' ? (rgb2hexCss(paint.color) || '').toUpperCase() : normalizeFillType(paint.type)
}

const getCssByType = paint => {
  const byType = {
    IMAGE: getImageCss(),
    GRADIENT_LINEAR: getLinearCss(paint.gradientStops),
    GRADIENT_RADIAL: getRadialCss(paint.gradientStops),
    GRADIENT_ANGULAR: getAngularCss(paint.gradientStops),
    GRADIENT_DIAMOND: getDiamondCss(paint.gradientStops),
    SOLID: rgb2hexCss(paint.color),
  }

  return byType[paint.type] || null
}

export const getBoxStyleByType = paint => ({
  background: getCssByType(paint)
})

export const normalizeCss = (paints) => {
  if (!paints) return null

  const solidColors = paints.filter(item => item.type === 'SOLID')
  const gradients = paints.filter(item => item.type !== 'SOLID')

  return [...gradients, ...solidColors].map(item => getCssByType(item)).join(', ')
}

export const getItemCss = paints => normalizeCss(paints) && `background: ${normalizeCss(paints)};`
