import React from 'react'
// import { IconGridColumns, IconGridRows, IconGridUniform } from '../../components/icons'
// import { rgb2hex, normalizeAlpha } from '../../utils/style-base'

const Grid = ({ code }) => {
  return (
    <>
      {code.layoutGrids.map((layout, index) => {
        return (
          <div key={`${layout.pattern}-${index}`} className="mb-3">
            {/* <div className="mr-2 flex-shrink-0">
              {layout.pattern === 'COLUMNS' && <IconGridColumns />}
              {layout.pattern === 'ROWS' && <IconGridRows />}
              {layout.pattern === 'GRID' && <IconGridUniform />}
            </div> */}
            <div className="mb-1">
              <span className="mb-2 block leading-none text-md text-gray-900 capitalize font-medium">
                {(layout.pattern || '').toLowerCase()}
              </span>
              <div className="flex flex-wrap">
                {layout.pattern === 'GRID' ? (
                  <div className="w-1/3 text-sm mb-1">
                    <span className="block font-medium text-gray-400">Grid Size</span>
                    <span className="block text-gray-900">{layout.sectionSize}</span>
                  </div>
                ) : (
                  <>
                    <div className="w-1/3 text-sm mb-1">
                      <span className="block font-medium text-gray-400">Count</span>
                      <span className="block text-gray-900">{layout.count}</span>
                    </div>
                    <div className="w-1/3 text-sm mb-1">
                      <span className="block font-medium text-gray-400">Type</span>
                      <span className="block text-gray-900 capitalize">{(layout.alignment || '').toLowerCase()}</span>
                    </div>
                    <div className="w-1/3 text-sm mb-1">
                      <span className="block font-medium text-gray-400">Margin</span>
                      <span className="block text-gray-900">{layout.offset}</span>
                    </div>
                    <div className="w-1/3 text-sm mb-1">
                      <span className="block font-medium text-gray-400">Gutter</span>
                      <span className="block text-gray-900">{layout.gutterSize}</span>
                    </div>
                    <div className="w-1/3 text-sm mb-1">
                      <span className="block font-medium text-gray-400">
                        {layout.pattern === 'COLUMNS' ? 'Width' : 'Height'}
                      </span>
                      <span className="block text-gray-900">Auto</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default Grid
