const apiServerUrl = process.env.REACT_APP_API_SERVER

export const collectionItemsGet = ({ id, types, page, searchQuery, pageSize = 24 }) =>
  fetch(
    `${apiServerUrl}/api/collection_items/?collection_uuids=${id}&item_types=${types}&page=${page}&page_size=${pageSize}&s=${searchQuery}`,
    {
      method: 'GET'
    }
  )

export const collectionGet = id =>
  fetch(`${apiServerUrl}/api/collections/${id}/`, {
    method: 'GET'
  })
