import React from 'react'
import { IconClose } from '../../components/icons'
import Color from './color'
import Text from './text'
import Grid from './grid'
import Effect from './effect'

const Panel = ({ onClose, item }) => {
  return (
    <div className="absolute bg-white h-full right-0 top-0 w-60 sm:w-80">
      <div className="px-8 py-6 bg-white shadow min-h-full w-full">
        <div className="flex flex-col sticky top-24">
          <div className="flex flex-nowrap mb-1 justify-between">
            <span className="block text-lg leading-6 font-medium text-black">{item.name}</span>
            <button
              onClick={() => onClose()}
              type="button"
              className="-mt-1 text-gray-900 outline-none focus:outline-none flex-shrink-0"
            >
              <IconClose />
            </button>
          </div>
          <div className="border-b mb-4">
            {item.meta_data.description && (
              <span className="block font-medium leading-tight mb-3">{item.meta_data.description}</span>
            )}
          </div>
          <div className="block">
            {item.item_type === 1 && <Color code={item.code} />}
            {item.item_type === 2 && <Text code={item.code} />}
            {item.item_type === 3 && <Effect code={item.code} />}
            {item.item_type === 4 && <Grid code={item.code} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Panel
