import { normalizeColor } from './style-base'

export const getNameByType = (type) => {
  if (!type) return null

  return type.toLowerCase().split('_').join(' ')
}

export const getItemCss = (effects) => {
  if (!Boolean(effects.length)) return null
  const layerBlur = effects.find((item) => item.type === 'LAYER_BLUR')
  const bgBlur = effects.find((item) => item.type === 'BACKGROUND_BLUR')
  const shadows = effects
    .filter(item => ['DROP_SHADOW', 'INNER_SHADOW'].includes(item.type))
    .map(
      item => `${item.type === 'INNER_SHADOW' ? 'inset ' : ''}${item.offset.x}px ${item.offset.y}px ${item.radius}px ${item.spread}px ${normalizeColor(item.color)}`
    )

  return [
    Boolean(shadows.length) && `box-shadow: ${shadows.join(', ')};`,
    layerBlur && `filter: blur(${layerBlur.radius}px);`,
    bgBlur && `backdrop-filter: blur(${bgBlur.radius}px);`
  ].filter(Boolean).join('\n')
}
