import React, { useState } from 'react'
import SearchForm from '../SearchForm'
import Panel from '../Panel'
import StyleType from './StyleType'

const Styles = ({ id, stylesCount, types }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [showPanel, setShowPanel] = useState(false)
  const [activeItem, setActiveItem] = useState(null)
  const pageSize = 300

  return (
    <>
      <SearchForm
        placeHolder={stylesCount > 1 && `Search all ${stylesCount} styles`}
        onChange={data => [setSearchQuery(data), setShowPanel(false), setActiveItem(null)]}
      />
      <div className={`${showPanel ? 'pl-4 sm:pl-6 lg:pl-16 sm:pr-80' : 'px-4 sm:px-6 lg:px-16'} flex-1 relative`}>
        <div className={`${showPanel ? 'pr-4' : ''} max-w-container mx-auto pb-12 pt-2 relative sm:pt-4`}>
          {stylesCount > 0 ? (
            <>
              {types && types.map(type => (
                <StyleType
                  id={id}
                  key={type}
                  q={searchQuery}
                  activeItemId={activeItem?.id}
                  pageSize={pageSize}
                  onItemClick={(item) => [setShowPanel(true), setActiveItem(item)]}
                  type={type}
                />
              ))}
            </>
          ) : (
            <div className="max-w-3xl mx-auto text-center text-xl leading-9 font-medium text-gray-600 mt-10">This collection has no exported public styles.</div>
          )}
        </div>
        {showPanel && activeItem && <Panel onClose={() => setShowPanel(false)} item={activeItem} />}
      </div>
    </>
  )
}

export default Styles
