import React, { Suspense, lazy } from 'react'
import { getTitleByType, getOpacityPercent, getBoxStyleByType, getItemCss } from '../../utils/style-colors'
import copy from 'copy-to-clipboard'
import { rgb2hexCss, hasBorder } from '../../utils/style-base'
import useCopy from '../../utils/use-copy'

const CssSyntaxHighlighter = lazy(() => import('../SyntaxHighlighter'))

const SolidColor = ({ paint }) => {
  const [copied, setCopied] = useCopy()

  return (
    <div className="flex mb-3 items-center">
      <div
        className={`${hasBorder(paint.color) ? 'border' : ''} h-6 w-6 mr-2 flex-shrink-0`}
        style={getBoxStyleByType(paint)}
      />
      <span className="block leading-none font-medium text-gray-700 text-sm">HEX</span>
      <span className="block leading-none ml-4 text-sm text-gray-600 uppercase">{rgb2hexCss(paint.color)}</span>
      <button
        onClick={() => {
          setCopied(true)
          copy(rgb2hexCss(paint.color))
        }}
        type="button"
        className="text-sm font-medium text-purple-500 ml-auto outline-none focus:outline-none"
      >
        {copied ? 'Copied' : 'Copy HEX'}
      </button>
    </div>
  )
}

const Color = ({ code }) => {
  const solidColors = code.paints.filter(item => item.type === 'SOLID')
  const gradients = code.paints.filter(item => item.type !== 'SOLID')
  const itemCss = getItemCss(code.paints)

  return (
    <>
      {solidColors.length > 0 && (
        <div className="mb-4">
          {solidColors.map((paint, index) => (
            <SolidColor key={`${paint.type}-${index}`} paint={paint} />
          ))}
        </div>
      )}
      {gradients.length > 0 && (
        <div className="mb-4">
          <span className="block mb-2 text-sm font-medium text-black">Gradients</span>
          {gradients.map((paint, index) => (
            <div key={`${paint.type}-${index}`} className="flex mb-3 items-center w-48">
              <div className="border h-6 w-6 mr-2 flex-shrink-0" style={getBoxStyleByType(paint)}></div>
              <span className="leading-none text-sm text-gray-900">{getTitleByType(paint)}</span>
              <span className="leading-none text-sm ml-auto text-gray-900">{getOpacityPercent(paint.opacity)}</span>
            </div>
          ))}
        </div>
      )}
      <Suspense fallback={<span className="block text-xs text-center">css compiling...</span>}>
        {itemCss && <CssSyntaxHighlighter>{itemCss}</CssSyntaxHighlighter>}
      </Suspense>
    </>
  )
}

export default Color
