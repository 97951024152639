import React, { Suspense, lazy } from 'react'
import { getItemList, getItemCss } from '../../utils/style-text'

const CssSyntaxHighlighter = lazy(() => import('../SyntaxHighlighter'))

const Text = ({ code }) => {
  const list = getItemList(code)
  const itemCss = getItemCss(code)

  return (
    <>
      {list.map(({ name, value }) => value && (
        <div key={name} className="flex mb-3 items-center w-48">
          <span className="leading-none text-sm text-gray-900">{name}</span>
          <span className="leading-none text-sm ml-auto text-gray-900">{value}</span>
        </div>
      ))}
      <Suspense fallback={<span className="block text-xs text-center">css compiling...</span>}>
        {itemCss && <CssSyntaxHighlighter>{itemCss}</CssSyntaxHighlighter>}
      </Suspense>
    </>
  )
}

export default Text
