import React, { useEffect, useState } from 'react'
import { useParams, matchPath, useLocation, useHistory } from 'react-router-dom'
import { collectionGet } from '../../api/collections'
import { checkHttpStatus } from '../../utils/api'
import Spinner from '../../components/spinner'
import { getStylesCount, getTypes } from '../../utils/collection'
import { Logo, IconCode, IconComponent, IconStyles, IconArrowRight } from '../../components/icons'
import { componentsPath, itemsPath, componentsString } from '../../utils/url'
import Components from '../../components/TypeComponent'
import Styles from '../../components/TypeStyles'

const CollectionItems = () => {
  const { id } = useParams()
  const { pathname } = useLocation()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [jsonUrlPath, setJsonUrlPath] = useState('')
  const [collection, setCollection] = useState({})
  const isComponentsPath = matchPath(pathname, { path: componentsPath, exact: true })
  const stylesUrl = `${itemsPath}${id}`
  const componentsUrl = `${itemsPath}${id}${componentsString}`

  useEffect(() => {
    let baseJsonUrl = `https://api.stylesup.io/api/collection_items/?collections=${collection?.id}`
    if (isComponentsPath) {
      setJsonUrlPath(`${baseJsonUrl}&item_types=5`)
    } else {
      setJsonUrlPath(`${baseJsonUrl}&item_types=1,2,3,4`)
    }
  }, [collection, isComponentsPath])

  useEffect(() => {
    if (id) {
      collectionGet(id)
        .then(checkHttpStatus)
        .then(data => {
          const stylesCount = getStylesCount(data.meta_data?.collected_items)
          const componentsCount = data.meta_data?.collected_items[5]

          setLoading(false)

          setCollection({
            id: data.id,
            name: data.name,
            desc: data.meta_data?.desc,
            bg: data.meta_data?.bg,
            stylesCount,
            componentsCount,
            types: getTypes(data.meta_data?.collected_items)
          })
        })
        .catch(err => {
          setLoading(false)
          setError(err.status === 404 ? 'Not found' : 'Something went wrong')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (loading) {
    return (
      <div className="flex justify-center py-16 text-purple-800 w-full">
        <Spinner className="text-purple-500" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex justify-center py-16 text-purple-800 w-full">
        <span>{error}</span>
      </div>
    )
  }

  return (
    <div className="flex flex-col min-h-screen text-gray-500">
      <header className="global-header bg-blue-500 px-4 sm:px-6 lg:px-16">
        <div className="max-w-container mx-auto">
          <div className="flex justify-between flex-col sm:flex-row border-b border-black border-opacity-10">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.figma.com/community/plugin/948597523260583481/Style-Manager"
              className="flex pt-4 pb-2 items-center text-sm leading-5"
            >
              <Logo className="mr-2 text-white" />
              <h1 className="font-display text-white text-1xl leading-9 font-semibold sm:text-1xl sm:leading-10">
                Style Manager
              </h1>
            </a>

            <div className="flex text-white text-sm pt-2 sm:pt-4 pb-2 leading-9 sm:leading-10">
              <span className="mr-2">Create your own style guide.</span>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.figma.com/community/plugin/948597523260583481/Style-Manager"
                className="flex"
              >
                <span className="font-bold">Get the plugin here</span>
                <span className="ml-2 m-auto">
                  <IconArrowRight className="text-white" />
                </span>
              </a>
            </div>
          </div>

          <div className="flex flex-wrap items-center justify-center pb-8 xl:flex-nowrap sm:pt-4">
            <div className="w-full flex-none text-center xl:w-auto xl:flex-auto xl:text-left mt-10 mr-0 xl:mr-10">
              {collection.name && (
                <h1 className="font-display text-white text-3xl leading-9 font-semibold sm:leading-10">
                  {collection.name}
                </h1>
              )}

              {collection.desc && (
                <h2 className="font-display text-white text-1xl leading-9 font-semibold text-opacity-60 sm:text-1xl sm:leading-10">
                  {collection.desc}
                </h2>
              )}
              <dl className="flex flex-wrap justify-center xl:justify-start whitespace-no-wrap text-purple-100 font-medium mt-1 leading-5">
                {collection.stylesCount > 0 && (
                  <div className="mx-3 sm:mx-4 xl:-ml-2 xl:mr-8">
                    <button
                      type="button"
                      className={`${
                        isComponentsPath ? 'opacity-50' : 'opacity-100'
                      } duration-200 flex focus:outline-none hover:opacity-100 items-center outline:none transition-opacity`}
                      disabled={!isComponentsPath}
                      onClick={() => {
                        history.push(stylesUrl)
                      }}
                    >
                      <IconStyles className="opacity-50" />
                      <span>{`${collection.stylesCount} style${collection.stylesCount > 1 ? 's' : ''}`}</span>
                    </button>
                  </div>
                )}

                {collection.componentsCount > 0 && (
                  <div className="mx-3 sm:mx-4 xl:ml-0 xl:mr-8">
                    <button
                      type="button"
                      className={`${
                        !isComponentsPath ? 'opacity-50' : 'opacity-100'
                      } duration-200 flex focus:outline-none hover:opacity-100 items-center outline:none transition-opacity`}
                      disabled={isComponentsPath}
                      onClick={() => {
                        history.push(componentsUrl)
                      }}
                    >
                      <IconComponent className="opacity-50" />
                      <span>{`${collection.componentsCount} component${
                        collection.componentsCount > 1 ? 's' : ''
                      }`}</span>
                    </button>
                  </div>
                )}
              </dl>
            </div>
            <div className="mt-10 w-full sm:w-auto">
              <a
                target="_blank"
                rel="noreferrer"
                href={jsonUrlPath}
                className="whitespace-nowrap group w-full sm:w-auto inline-flex items-center justify-center text-purple-700 font-medium leading-none bg-white rounded-lg shadow-sm hover:shadow-lg py-2 px-4 border border-transparent transform hover:-translate-y-0.5 transition-translate duration-150"
              >
                <IconCode className="mr-3 opacity-50 group-hover:opacity-75 transition-opacity duration-150" />
                <span className="opacity-75 group-hover:opacity-100 transition-opacity duration-150">JSON</span>
              </a>
            </div>
          </div>
        </div>
      </header>

      <main className="flex flex-1 flex-col">
        {isComponentsPath ? (
          <Components id={id} componentsCount={collection.componentsCount} bg={collection?.bg} />
        ) : (
          <Styles id={id} stylesCount={collection.stylesCount} types={collection.types} />
        )}
      </main>
    </div>
  )
}

export default CollectionItems
