import React, { Suspense, lazy } from 'react'
import { getNameByType, getItemCss } from '../../utils/style-effects'
// import { IconEffects } from '../../components/icons'

const CssSyntaxHighlighter = lazy(() => import('../SyntaxHighlighter'))

const Effect = ({ code }) => {
  const itemCss = getItemCss(code.effects)

  return (
    <>
      {code.effects.map((effect, index) => {
        const isBlur = ['LAYER_BLUR', 'BACKGROUND_BLUR'].includes(effect.type)
        const isShadow = ['DROP_SHADOW', 'INNER_SHADOW'].includes(effect.type)

        return (
          <div key={`${effect.type}-${index}`} className="mb-3">
            {/* <div className="mr-2 flex-shrink-0">
              <IconEffects />
            </div> */}
            <div className="mb-2 flex items-center">
              <span className={`${isBlur ? 'w-32' : ''} leading-none text-sm text-gray-900 capitalize font-medium`}>
                {getNameByType(effect.type)}
              </span>
              {isBlur && <span className="leading-none text-sm text-gray-900">{effect.radius}</span>}
            </div>
            {isShadow && (
              <div className="mb-2 flex items-center text-sm leading-none">
                <span className="text-gray-400 mr-2">X</span>
                <span className="text-gray-900 mr-4">{effect.offset.x}</span>
                <span className="text-gray-400 mr-2">Y</span>
                <span className="text-gray-900 mr-4">{effect.offset.y}</span>
                <span className="text-gray-400 mr-2">Blur</span>
                <span className="text-gray-900 mr-4">{effect.radius}</span>
                <span className="text-gray-400 mr-2">Spread</span>
                <span className="text-gray-900">{effect.spread}</span>
              </div>
            )}
          </div>
        )
      })}
      <Suspense fallback={<span className="block text-xs text-center">css compiling...</span>}>
        {itemCss && <CssSyntaxHighlighter>{itemCss}</CssSyntaxHighlighter>}
      </Suspense>
    </>
  )
}

export default Effect
