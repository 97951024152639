export const getStylesCount = (items = {}) => {
  return Object.values(items).filter(item => Array.isArray(item)).flat().length
}

export const getTypes = (items = {}) => {
  return Object.keys(items).filter(item => Array.isArray(items[item]) && Boolean(items[item].length))
}

export const nameByType = {
  1: 'Colors',
  2: 'Text',
  3: 'Effects',
  4: 'Grid'
}
